import { TOGGLE_IMPORTANT_MESSAGE, TOGGLE_NAVIGATION } from '../actions';

export default function reducer(state, action) {
  switch (action.type) {
    case TOGGLE_IMPORTANT_MESSAGE:
      return {
        ...state,
        importantMessageIsOpen: !state.importantMessageIsOpen,
      };
    case TOGGLE_NAVIGATION:
      return {
        ...state,
        navigationIsOpen: !state.navigationIsOpen,
      };
    default:
      return state;
  }
}
