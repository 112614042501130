import { TOGGLE_IMPORTANT_MESSAGE, TOGGLE_NAVIGATION } from './action-types';

export function toggleNavigation() {
  return {
    type: TOGGLE_NAVIGATION,
  };
}

export function toggleImportantMessage() {
  return {
    type: TOGGLE_IMPORTANT_MESSAGE,
  };
}
